import { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import * as React from 'react';
import Login from 'src/modules/auth/views/Login';

const namespacesRequired = ['common', 'login', 'auth', 'navbar'];

const LoginPage: NextPage = () => {
  return (
    <div className="address-root h-screen px-4 md:px-10">
      <Login />
    </div>
  );
};

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, namespacesRequired)),
    },
    revalidate: 1000,
  };
}

export default LoginPage;
