import * as React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import useAlert from 'src/hooks/useAlert';
import { LoginStepFormValues, PhoneFormValues } from '../types';
import PhoneInput from 'src/components/PhoneInput';
import { useTranslation } from 'next-i18next';
import Form from '@SnackatCafe/snackat-ui/dist/Form';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import Alert from '@SnackatCafe/snackat-ui/dist/Alert';
import { useLogin } from '../api/mutations';
import { useErrorBoundaryContext } from 'src/modules/error/ErrorBoundaryProvider';
import { NOT_REGISTERED } from 'src/modules/error/api/constants';
import apiErrorMessage from 'src/utils/apiErrorMessage';
import { useUserContext } from '../providers/UserProvider';
import useNavigator from 'src/hooks/useNavigator';
import Analytics from 'src/utils/analytics';

export interface PhoneStepProps {
  phone: PhoneFormValues;
  setPhone: (phone: PhoneFormValues) => void;
  setIsOtpStep: (val: boolean) => void;
}

const PhoneStep: React.FC<PhoneStepProps> = props => {
  const {
    phone = {
      code: '+971',
      number: null,
    },
    setPhone,
    setIsOtpStep,
  } = props;

  const { t } = useTranslation(['login', 'common']);

  const formMethods = useForm<LoginStepFormValues>({
    defaultValues: { phone },
    mode: 'all',
  });

  const [alert, setAlert] = useAlert();
  const { onMutationError } = useErrorBoundaryContext();
  const navigate = useNavigator();
  const { mutate: login, isLoading } = useLogin();

  const { setUserPhone } = useUserContext();

  const onSubmit: SubmitHandler<LoginStepFormValues> = values => {
    login(
      { phoneNumber: values.phone },
      {
        onSuccess: data => {
          setPhone(values.phone);
          setIsOtpStep(true);
          Analytics.logLoginEvent(data.user);
        },
        onError: error => {
          const message = apiErrorMessage(error);
          if (message === NOT_REGISTERED) {
            setUserPhone(values.phone);
            navigate('/signup', { replace: true });
          } else {
            setAlert({
              status: 'error',
              message: onMutationError(error, { suppress: true }),
            });
          }
        },
      }
    );
  };

  return (
    <FormProvider {...formMethods}>
      <div className="flex flex-col items-center">
        <Form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          className="mb-3 flex w-full flex-col gap-y-3"
        >
          <PhoneInput readOnlyCode />
          <Button
            variant="solid"
            type="submit"
            rounded="lg"
            className="w-full rounded-full shadow-md shadow-primary-400/30 hover:bg-white hover:text-primary-500"
            isLoading={isLoading}
          >
            {t('common:buttons.next')}
          </Button>
        </Form>
      </div>

      <Alert show={!!alert.message} className="mb-2" status={alert.status}>
        {alert.message}
      </Alert>
    </FormProvider>
  );
};

export default PhoneStep;
