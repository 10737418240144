import * as React from 'react';
import Card from '@SnackatCafe/snackat-ui/dist/Card';
import Button from '@SnackatCafe/snackat-ui/dist/Button';
import { UserAddIcon } from '@heroicons/react/solid';
import { useTranslation } from 'next-i18next';

import getUserCountryCode from 'src/utils/getUserCountryCode';
import { useNavigator } from 'src/hooks/useNavigator/useNavigator';

import { PhoneFormValues } from '../types';
import OtpStep from '../components/OtpStep';
import PhoneStep from '../components/PhoneStep';
import { useLocationContext } from 'src/modules/app/providers/LocationProvider';
import { useUserContext } from '../providers/UserProvider';

const Login: React.FC = () => {
  const { t } = useTranslation('auth');
  const { userLocation } = useLocationContext();
  const navigate = useNavigator();
  const { userPhone } = useUserContext();

  const userCountryCode = getUserCountryCode(userLocation?.country);

  const [isOtpStep, setIsOtpStep] = React.useState(false);
  const [phone, setPhone] = React.useState<PhoneFormValues>(
    userPhone
      ? userPhone
      : {
          code: userCountryCode ? userCountryCode : '+971',
          number: null,
        }
  );

  return (
    <div className=" m-auto flex w-full flex-col gap-y-10 pt-4 md:pt-0">
      <div className="m-auto font-hLtr text-2xl font-bold text-secondary-500 rtl:font-hRtl md:text-4xl">
        {t('titles.login')}
      </div>
      <Card className=" m-auto flex w-full items-center justify-center rounded-none border-lines-500 shadow-sm md:w-[450px]">
        <Card.Body className="m-0 !w-full px-3 md:px-4 md:py-4">
          <div className="m-auto flex w-full flex-col items-center justify-center gap-y-4">
            <div className="flex w-full items-center justify-center bg-white">
              <div className="w-full md:w-96">
                {isOtpStep ? (
                  <OtpStep phone={phone} setIsOtpStep={setIsOtpStep} />
                ) : (
                  <PhoneStep
                    setIsOtpStep={setIsOtpStep}
                    phone={phone}
                    setPhone={setPhone}
                  />
                )}
              </div>
            </div>
            <div className="m-auto flex flex-col items-center justify-center gap-y-0 ">
              <div className="text-sm font-semibold text-icons-500">
                {t('labels.notRegistered')}
              </div>
              <div onClick={() => navigate('/signup', { replace: true })}>
                <Button
                  leftIcon={UserAddIcon}
                  variant="text"
                  className="m-0 p-0 text-secondary-500 hover:text-primary-500"
                >
                  {t('buttons.registerNow')}
                </Button>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
